import React from 'react'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import { Link } from 'gatsby'
import CtaSalas from '../images/cta-video.png'

import {
  inspired,
  container,
  title,
  videoPlay
} from './styles/AoVivo.styles'

const AoVivo = () => (
  <div css={inspired} >
    <div css={container}>
      <div css={title}>Escolha a sala de seu interesse:</div>
      <div id="plain-react">
        <Tabs
          onChange={tab => console.log(`Tab selected: ${tab}`)} // eslint-disable-line no-console
        >
          <div style={{ marginBottom: 36 }}>
            <Link
              to='/sala-enem'
            >
              <TabLink to="tab1">Sala ENEM</TabLink>
            </Link>
            <Link
              to='/sala-ime-ita'
            >
              <TabLink to="tab3">Sala IME/ITA</TabLink>
            </Link>
          </div>
          <div style={{ paddingBottom: 100 }}>
            <TabContent for="tab1">
              <div css={videoPlay}style={{ justifyContent: 'center' }}>
                <img src={CtaSalas} alt='/' />
              </div>
            </TabContent>
          </div>
        </Tabs>
      </div>
    </div>
  </div>
);

export default AoVivo