import React, { Fragment } from 'react'
import ItemsCarousel from '../components/ItemsCarousel'
import Professor from '../images/novas/teachers/professor.png'
import Professor2 from '../images/novas/teachers/professor2.png'
import Professor3 from '../images/novas/teachers/professor3.png'
import Professor4 from '../images/novas/teachers/professor4.png'
import Professor5 from '../images/novas/teachers/professor5.png'
import Professor6 from '../images/novas/teachers/professor6.png'
import Professor7 from '../images/novas/teachers/professor7.png'
import Professor8 from '../images/novas/teachers/professor8.png'
import Professor9 from '../images/novas/teachers/professor9.png'
import Professor10 from '../images/novas/teachers/professor10.png'
import Professor11 from '../images/novas/teachers/professor11.png'
import Professor12 from '../images/novas/teachers/professor12.png'
import Professor13 from '../images/novas/teachers/professor13.png'
import Professor14 from '../images/novas/teachers/professor14.png'
import Professor15 from '../images/novas/teachers/professor15.png'
import Professor16 from '../images/novas/teachers/professor16.png'
import Professor17 from '../images/novas/teachers/professor17.png'
import Professor18 from '../images/novas/teachers/professor18.png'
import Professor19 from '../images/novas/teachers/professor19.png'
import Professor20 from '../images/novas/teachers/professor20.png'
import Professor21 from '../images/novas/teachers/professor21.png'
import Professor22 from '../images/novas/teachers/professor22.png'
import Professor23 from '../images/novas/teachers/professor23.png'
import Professor24 from '../images/novas/teachers/professor24.png'
import Professor25 from '../images/novas/teachers/professor25.png'
import Professor26 from '../images/novas/teachers/professor26.png'
import Professor27 from '../images/novas/teachers/professor27.png'
import Professor28 from '../images/novas/teachers/professor28.png'
import Professor29 from '../images/novas/teachers/professor29.png'
import Professor30 from '../images/novas/teachers/professor30.png'
import Professor31 from '../images/novas/teachers/professor31.png'
import Professor32 from '../images/novas/teachers/professor32.png'
import Professor33 from '../images/novas/teachers/professor33.png'
import Professor34 from '../images/novas/teachers/professor34.png'
import Professor35 from '../images/novas/teachers/professor35.png'
import Professor36 from '../images/novas/teachers/professor36.png'
import Professor37 from '../images/novas/teachers/professor37.png'
import Professor38 from '../images/novas/teachers/professor38.png'
import Professor39 from '../images/novas/teachers/professor39.png'
import Professor40 from '../images/novas/teachers/professor40.png'
import Professor41 from '../images/novas/teachers/professor41.png'
import Professor42 from '../images/novas/teachers/professor42.png'
import Professor43 from '../images/novas/teachers/professor43.png'
import Professor44 from '../images/novas/teachers/professor44.png'
import Professor45 from '../images/novas/teachers/professor45.png'
import Professor46 from '../images/novas/teachers/professor46.png'
import Professor47 from '../images/novas/teachers/professor47.png'
import Professor48 from '../images/novas/teachers/professor48.png'
import Professor49 from '../images/novas/teachers/professor49.png'
import Professor50 from '../images/novas/teachers/professor50.png'
import Professor51 from '../images/novas/teachers/professor51.png'
import Professor52 from '../images/novas/teachers/professor52.png'
import Professor53 from '../images/novas/teachers/professor53.png'
import Professor54 from '../images/novas/teachers/professor54.png'
import Professor55 from '../images/novas/teachers/professor55.png'
import Professor56 from '../images/novas/teachers/professor56.png'
import Professor57 from '../images/novas/teachers/professor57.png'
import Professor58 from '../images/novas/teachers/professor58.png'
import Professor59 from '../images/novas/teachers/professor59.jpeg'
import Professor60 from '../images/novas/teachers/professor60.png'
import Professor61 from '../images/novas/teachers/professor61.png'
import Professor62 from '../images/novas/teachers/professor62.png'
import Professor63 from '../images/novas/teachers/professor63.png'
import Professor64 from '../images/novas/teachers/professor64.png'
import Professor65 from '../images/novas/teachers/professor65.png'
import Professor66 from '../images/novas/teachers/professor66.png'
import Professor67 from '../images/novas/teachers/professor67.png'
import Professor68 from '../images/novas/teachers/professor68.png'
import Professor69 from '../images/novas/teachers/professor69.png'
import Professor70 from '../images/novas/teachers/professor70.png'
import Professor71 from '../images/novas/teachers/professor71.png'
import Professor72 from '../images/novas/teachers/professor72.png'
import Professor73 from '../images/novas/teachers/professor73.png'
import Professor74 from '../images/novas/teachers/professor74.png'
import Professor75 from '../images/novas/teachers/professor75.png'
import Professor76 from '../images/novas/teachers/professor76.png'
import Professor77 from '../images/novas/teachers/professor77.png'
import Professor78 from '../images/novas/teachers/professor78.png'
import Professor79 from '../images/novas/teachers/professor79.png'
import Professor80 from '../images/novas/teachers/professor80.png'
import Professor81 from '../images/novas/teachers/professor81.png'
import Professor82 from '../images/novas/teachers/professor82.png'
import Professor83 from '../images/novas/teachers/professor83.png'
import Professor84 from '../images/novas/teachers/professor84.png'
import Professor85 from '../images/novas/teachers/professor85.png'
import Professor86 from '../images/novas/teachers/professor86.png'
import Professor87 from '../images/novas/teachers/professor87.png'
import Professor88 from '../images/novas/teachers/professor88.png'
import Professor89 from '../images/novas/teachers/professor89.png'
import Professor90 from '../images/novas/teachers/professor90.png'
import Professor91 from '../images/novas/teachers/professor91.jpeg'
import {
  inspired,
  container,
  title,
  ProfessoresBox,
  ProfessoresContent,
  columns, 
  column,
  visionBox,
  // desktop,
  mobile
} from './styles/Professores.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={title}>
          <span>PROFESSORES</span><br />
          Conheça os professores
        </div>
        <div css={[visionBox]}>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Átila Zanone</h2>
                  <h3>Matemática - Fibonacci</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor2} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Alexandre Folz</h2>
                  <h3>Física - Elite Curitiba</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor3} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Alexandre Marques</h2>
                  <h3>História - Elite Porto Alegre</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor4} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Alexandre Pinto</h2>
                  <h3>Química - Ciências Aplicadas</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor5} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Daniel Lavouras</h2>
                  <h3>Física - Elite Pré-Vestibular</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor6} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Brasilino Assaid</h2>
                  <h3>História - Influence</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor7} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Cassiano Cesar</h2>
                  <h3>Biologia - Elite Curitiba</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor8} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Cleber Ribeiro</h2>
                  <h3>Física - Elite Belém</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor9} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Daniel Rojas</h2>
                  <h3>Matemática - MADAN</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor10} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Felipe Gomes</h2>
                  <h3>Geografia - Progressão</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor11} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Fernandes Epitácio</h2>
                  <h3>Geografia - Objetivo</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor12} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Fernando Viana</h2>
                  <h3>Matemática - ISSO</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor13} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Franco Pontes</h2>
                  <h3>Química - Moderno</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor14} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Fred Ganem</h2>
                  <h3>Matemática - Bertoni</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor21} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Giba</h2>
                  <h3>Biologia - Elite/Sobiologiba</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor16} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Higor Prucoli</h2>
                  <h3>História - MADAN</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor17} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Jonas Alves</h2>
                  <h3>Biologia - Ciências Aplicadas</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor18} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Kamila Sena</h2>
                  <h3>Química - Seriös</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor19} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Leonardo Sá</h2>
                  <h3>Biologia - Seriös/Átrio</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor20} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Marcelo Xavier</h2>
                  <h3>Matemática - Futuro</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor22} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Max Paiva</h2>
                  <h3>Matemática - Objetivo-PI</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor23} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Onofre Campos</h2>
                  <h3>Matemática - 7 de Setembro</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor24} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Otávio Araújo </h2>
                  <h3>Química - Bertoni</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor25} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Paulo Thiago</h2>
                  <h3>Biologia - Elite-Belém</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor27} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Raul Victor</h2>
                  <h3>Matemática - Seriös/Átrio</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor28} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Reinaldo Pandovani</h2>
                  <h3>Química - Colégio Einstein</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor29} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Rhuan de Castro</h2>
                  <h3>Biologia - Moderno</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor30} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Rodrigo Menezes</h2>
                  <h3>Matemática - ProMilitares</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor31} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Romulo Bolivar</h2>
                  <h3>Redação - PROENEM</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor32} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Vanessa Silva</h2>
                  <h3>História - Elite Porto Alegre</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor15} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Vitório Gauss</h2>
                  <h3>Matemática - ELO Salvador</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor26} alt='' />
                <div css={ProfessoresContent}>
                  <h2>William Mourão</h2>
                  <h3>Matemática -Matematicawill</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor33} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Alvaro de Jesus</h2>
                  <h3>Matemática - Matriz</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor34} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Breno Almeida</h2>
                  <h3>Física - ISO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor35} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Fernando da Silva </h2>
                  <h3>Inglês - Trilíngue Inovação</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor36} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Ignacio Manuel Gabis</h2>
                  <h3>Espanhol - Trilíngue Inovação</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor37} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Umberto Malanga</h2>
                  <h3>Matemática - Planck</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor38} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Adenilson Bonfim</h2>
                  <h3>Matemática - Elite</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor39} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Adriano Hilário</h2>
                  <h3>Química - Progressão</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor40} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Antônio Jefferson</h2>
                  <h3>Química - Objetivo</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor41} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Celso Faria (CELSÃO)</h2>
                  <h3>Matemática - Átrio</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor42} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Hugo Cimério</h2>
                  <h3>Química - Elite Belém</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor43} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Natalia Leme</h2>
                  <h3>Português - Einstein</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor44} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Renato Madeira</h2>
                  <h3>Matemática - Foco-Ensino</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor45} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Rômulo Garcia</h2>
                  <h3>Matemática - Progressão</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor46} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Tainan Diniz</h2>
                  <h3>História - Futuro VIP</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor47} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Arthur Hermsdorff</h2>
                  <h3>Matemática - Fibonacci</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor48} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Felipe de Castro</h2>
                  <h3>Matemática - MADAN</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor49} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Laiz Pereira</h2>
                  <h3>Português - ELO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor50} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Mariano de Azevedo</h2>
                  <h3>História - Ciêcias Aplicadas</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor51} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Ricardo Fagundes</h2>
                  <h3>Física - PROMILITARES</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor52} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Vinícius Medrado</h2>
                  <h3>Química - Átrio</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor53} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Flávio Santos Pinto</h2>
                  <h3>Química - Influence</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor54} alt='' />
                <div css={ProfessoresContent}>
                  <h2>José Melo Neto</h2>
                  <h3>Matemática - Paulo VI</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor55} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Eduardo Brito</h2>
                  <h3>Matemática - Matriz</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor56} alt='' />
                <div css={ProfessoresContent}>
                  <h2>João Luiz</h2>
                  <h3>Física - Foco</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor57} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Sidnei Lopes</h2>
                  <h3>História - Einstein</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor58} alt='' />
                <div css={ProfessoresContent}>
                  <h2>José (MANINHO)</h2>
                  <h3>Física - </h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor59} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Leandro Linhares</h2>
                  <h3>Matemática - Elite</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor60} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Fábio Roberto</h2>
                  <h3>Física - Laplace</h3>
                </div>
              </div>
            </div>
          </div>
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor61} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Marcela Querino</h2>
                  <h3>Biologia - ISO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor62} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Waldson Muniz</h2>
                  <h3>Português - Seriös</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor63} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Fred Frydman</h2>
                  <h3>Física - ELO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor64} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Grabriel Ribeiro</h2>
                  <h3>Física - CASD</h3>
                </div>
              </div>
            </div>
          </div>      
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor65} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Wendel Santos</h2>
                  <h3>Física - Cognitivo</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor66} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Raquel Fanale Gerab</h2>
                  <h3>História - CASD</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor67} alt='' />
                <div css={ProfessoresContent}>
                  <h2>João Filho</h2>
                  <h3>Literatura - Objetivo</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor68} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Patrícia Barra</h2>
                  <h3>Química - Apogeu</h3>
                </div>
              </div>
            </div>
          </div> 
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor69} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Matheus Pinheiros</h2>
                  <h3>Física - 7 de Setembro</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor70} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Paulo Andrade</h2>
                  <h3>Filosofia - AO CUBO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor71} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Paulo Henrique</h2>
                  <h3>Física - Planck</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor72} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Vagner Figueira</h2>
                  <h3>Matemática - Anglo/Kuadro</h3>
                </div>
              </div>
            </div>
          </div>      
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor73} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Taisi Viveiros</h2>
                  <h3>Português - Foco</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor74} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Rairis Faetti</h2>
                  <h3>Matemática</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor75} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Eric Cariello</h2>
                  <h3>Matemática - ELITE RJ</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor76} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Zé Carlos</h2>
                  <h3>Física - Átrio</h3>
                </div>
              </div>
            </div>
          </div>   
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor77} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Haroldo Filho</h2>
                  <h3>Matemática</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor78} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Carlos Nehab</h2>
                  <h3>Matemática - Camões Pinochio</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor79} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Adriano Jorge</h2>
                  <h3>Matemática - Ciências Aplicadas</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor80} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Priscila Portela</h2>
                  <h3>Biologia - QI</h3>
                </div>
              </div>
            </div>
          </div>  
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor81} alt='' />
                <div css={ProfessoresContent}>
                  <h2>José Carlos</h2>
                  <h3>Física - QI</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor82} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Jean Lira</h2>
                  <h3>Matemática</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor83} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Felipe Stocco</h2>
                  <h3>Matemática - Elite</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor84} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Edney Schemberer</h2>
                  <h3>Biologia - Bertoni</h3>
                </div>
              </div>
            </div>
          </div> 
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor85} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Thais Djenane</h2>
                  <h3>Física - Progressão</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor86} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Coronel Felix</h2>
                  <h3>História - ELO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor87} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Rodrigo Aguiar </h2>
                  <h3>Matemática - IBAYES</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor88} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Thiago Simões</h2>
                  <h3>Geografia - APOGEU</h3>
                </div>
              </div>
            </div>
          </div>      
          <div css={columns}>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor89} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Rodrigo Sales </h2>
                  <h3>Português - ISO</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor90} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Wagih Rassi</h2>
                  <h3>Física - Seriös</h3>
                </div>
              </div>
            </div>
            <div css={column}>
              <div css={ProfessoresBox}>
                <img src={Professor91} alt='' />
                <div css={ProfessoresContent}>
                  <h2>Prof. Figa</h2>
                  <h3>História</h3>
                </div>
              </div>
            </div>
          </div>      
        </div>
        {false && <div css={mobile}>
          <ItemsCarousel />
        </div>}
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
