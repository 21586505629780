import React, { Component } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import * as Scroll from 'react-scroll'
import NavBar from '../components/NavBar'
import AoVivo from '../components/AoVivo'
// import Banner from '../components/HeroBannerSite'
import EstudeAqui from '../components/EstudeAqui'
import Professores from '../components/Professores'
import ProgramacaoHome from '../components/ProgramacaoHome'
import Footer from '../components/Footer'

import { globalStyles } from '../components/layout.styles'

export const MenuContext = React.createContext({
  visible: false,
  setVisible: () => {}
})

class SitePage extends Component {
  constructor (props) {
    super(props)
    this.setVisible = (value) => {
      this.setState(({ visible: value }))
    }

    this.state = {
      visible: false,
      setVisible: this.setVisible
    }
  }

  render () {
    
    return (
      <div>
        <Global styles={globalStyles} />
        <SEO title="Aulão Brasil - Os melhores professores, das melhores escolas do Brasil." />
        <MenuContext.Provider value={this.state}>
          <Scroll.Element
            id='header'
          >
            <NavBar location={this.props.location} /> 
          </Scroll.Element>
          <AoVivo />
          {/* <Banner /> */}
          <Scroll.Element>
            <div id='download-material' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <ProgramacaoHome />
          </Scroll.Element>
          {false && <Scroll.Element>
            <div id='-' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <EstudeAqui />
          </Scroll.Element>}
          <Scroll.Element>
            <div id='professores' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <Professores />
          </Scroll.Element>
          <Scroll.Element>
            <div id='escolas' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <Footer />
          </Scroll.Element>
        </MenuContext.Provider>
      </div>
    )
  }
}

export default SitePage
