import { css } from '@emotion/core'

export const inspired = css`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 8rem;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  @media (min-width: 260px) and (max-width: 736px) {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
`
export const title = css`
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  color: #333333;
  margin-bottom: 36px;
  span {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #683EA2;
    font-family: 'Oswald', sans-serif;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
    font-size: 42px;
    line-height: 52px;
  }
`

export const ProfessoresBox = css`
  position: relative;
  /* padding: 36px 24px 36px 24px;*/
  width: 270px; 
  background: #FFFFFF;
  /* box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.2); */
  border-radius: 4px;
  cursor: default;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  transition: all 0.25s linear 0s;
  text-align: center;
  img {
    z-index: 999;
    border-radius: 20px;
  }
  :hover {
    /* box-shadow: 0 5px 25px rgba(0,0,0,.1); */
    transform: translate(0px, -4px);
  }
  /* @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    margin: 0 18px
  } */
  @media (min-width: 260px) and (max-width: 980px) {
    margin: 0 auto;
  }
`
export const ProfessoresContent = css`
  background: #F5FF00;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 41px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  margin-top: -30px;
  h2 {
    font-weight: bold;
    font-size: 21px;
    line-height: 32px;
    color: #333333;
    margin-top: 16px;
    font-family: 'Montserrat', sans-serif;
  }
  h3 {
    font-size: 14px;
    line-height: 32px;
    color: #333333;
    font-family: 'Montserrat',sans-serif;
  }
`

export const columns = css`
  display: flex;
  /* flex-wrap: wrap; */
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    width: 100%;
  }
`

export const column = css`
  /* flex: 1 0 18%;  */
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const btn = css`
  width: 328px;
  height: 65px;
  border-radius: 4px;
  background: #026FC2;
  /* border: 1px solid #026FC2; */
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 18px; 
  line-height: 22px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
export const visionBox = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  p {
    font-size: 24px;
    line-height: 120%;
    color: #C4EFFA;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
`

export const desktop = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none;
  }
`
export const mobile = css`
  @media (min-width: 1001px) {
    display: none;
  }
`