import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Professor from '../images/novas/teachers/professor.png'
import Professor2 from '../images/novas/teachers/professor2.png'
import Professor3 from '../images/novas/teachers/professor3.png'
import Professor4 from '../images/novas/teachers/professor4.png'
import Professor5 from '../images/novas/teachers/professor5.png'
import Professor6 from '../images/novas/teachers/professor6.png'
import Professor7 from '../images/novas/teachers/professor7.png'
import Professor8 from '../images/novas/teachers/professor8.png'
import Professor9 from '../images/novas/teachers/professor9.png'
import Professor10 from '../images/novas/teachers/professor10.png'
import Professor11 from '../images/novas/teachers/professor11.png'
import Professor12 from '../images/novas/teachers/professor12.png'
import Professor13 from '../images/novas/teachers/professor13.png'
import Professor14 from '../images/novas/teachers/professor14.png'
import Professor15 from '../images/novas/teachers/professor15.png'
import Professor16 from '../images/novas/teachers/professor16.png'
import Professor17 from '../images/novas/teachers/professor17.png'
import Professor18 from '../images/novas/teachers/professor18.png'
import Professor19 from '../images/novas/teachers/professor19.png'
import Professor20 from '../images/novas/teachers/professor20.png'
import Professor21 from '../images/novas/teachers/professor21.png'
import Professor22 from '../images/novas/teachers/professor22.png'
import Professor23 from '../images/novas/teachers/professor23.png'
import Professor24 from '../images/novas/teachers/professor24.png'
import Professor25 from '../images/novas/teachers/professor25.png'
import Professor26 from '../images/novas/teachers/professor26.png'
import Professor27 from '../images/novas/teachers/professor27.png'
import Professor28 from '../images/novas/teachers/professor28.png'
import Professor29 from '../images/novas/teachers/professor29.png'
import Professor30 from '../images/novas/teachers/professor30.png'
import Professor31 from '../images/novas/teachers/professor31.png'
import Professor32 from '../images/novas/teachers/professor32.png'

import {
  ProfessoresBox,
  ProfessoresContent,
} from './styles/Professores.styles'

class SimpleSlider extends React.Component {

  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
            arrows: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
            arrows: false,
            dots: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false,
          }
        }
      ]
    }
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor} alt='' />
              <div css={ProfessoresContent}>
                <h2>Átila Zanone</h2>
                <h3>Matemática - Fibonacci</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor2} alt='' />
              <div css={ProfessoresContent}>
                <h2>Alexandre Folz</h2>
                <h3>Física - Elite Curitiba</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor3} alt='' />
              <div css={ProfessoresContent}>
                <h2>Alexandre Marques</h2>
                <h3>HistoÌria - Elite Porto Alegre</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor4} alt='' />
              <div css={ProfessoresContent}>
                <h2>Alexandre Pinto</h2>
                <h3>Química - Ciências Aplicadas</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor5} alt='' />
              <div css={ProfessoresContent}>
                <h2>Daniel Lavouras</h2>
                <h3>Física - Elite Pré-Vestibular</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor6} alt='' />
              <div css={ProfessoresContent}>
                <h2>Brasilino Assaid</h2>
                <h3>HistoÌria - Influence</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor7} alt='' />
              <div css={ProfessoresContent}>
                <h2>Cassiano Cesar</h2>
                <h3>Biologia - Elite Curitiba</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor8} alt='' />
              <div css={ProfessoresContent}>
                <h2>Cleber Ribeiro</h2>
                <h3>Física - Elite Belém</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor9} alt='' />
              <div css={ProfessoresContent}>
                <h2>Daniel Rojas</h2>
                <h3>Matemática - MADAN</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor10} alt='' />
              <div css={ProfessoresContent}>
                <h2>Felipe Gomes</h2>
                <h3>Geografia - Progressão</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor11} alt='' />
              <div css={ProfessoresContent}>
                <h2>Fernandes Epitácio</h2>
                <h3>Geografia - Objetivo</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor12} alt='' />
              <div css={ProfessoresContent}>
                <h2>Fernando Viana</h2>
                <h3>Matemática - ISSO</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor13} alt='' />
              <div css={ProfessoresContent}>
                <h2>Franco Pontes</h2>
                <h3>Química - Moderno</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor14} alt='' />
              <div css={ProfessoresContent}>
                <h2>Fred Ganem</h2>
                <h3>Matemática - Bertoni</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor21} alt='' />
              <div css={ProfessoresContent}>
                <h2>Giba</h2>
                <h3>Biologia - Elite/Sobiologiba</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor16} alt='' />
              <div css={ProfessoresContent}>
                <h2>Higor Prucoli</h2>
                <h3>HistoÌria - MADAN</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor17} alt='' />
              <div css={ProfessoresContent}>
                <h2>Jonas Alves</h2>
                <h3>Biologia - Ciências Aplicadas</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor18} alt='' />
              <div css={ProfessoresContent}>
                <h2>Kamila Sena</h2>
                <h3>Química - Seriös</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor19} alt='' />
              <div css={ProfessoresContent}>
                <h2>Leonardo Sá</h2>
                <h3>Biologia - Seriös/Átrio</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor20} alt='' />
              <div css={ProfessoresContent}>
                <h2>Marcelo Xavier</h2>
                <h3>Matemática - Futuro</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor22} alt='' />
              <div css={ProfessoresContent}>
                <h2>Max Paiva</h2>
                <h3>Matemática - Objetivo-PI</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor23} alt='' />
              <div css={ProfessoresContent}>
                <h2>Onofre Campos</h2>
                <h3>Matemática - 7 de Setembro</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor24} alt='' />
              <div css={ProfessoresContent}>
                <h2>Otávio Araújo </h2>
                <h3>Química - Bertoni</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor25} alt='' />
              <div css={ProfessoresContent}>
                <h2>Paulo Thiago</h2>
                <h3>Biologia - Elite-Belém</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor27} alt='' />
              <div css={ProfessoresContent}>
                <h2>Raul Victor</h2>
                <h3>Matemática - Seriös/Átrio</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor28} alt='' />
              <div css={ProfessoresContent}>
                <h2>Reinaldo Pandovani</h2>
                <h3>Biologia - Colégio Einstein</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor29} alt='' />
              <div css={ProfessoresContent}>
                <h2>Rhuan de Castro</h2>
                <h3>Biologia - Moderno</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor30} alt='' />
              <div css={ProfessoresContent}>
                <h2>Rodrigo Menezes</h2>
                <h3>Matemática - ProMilitares</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor31} alt='' />
              <div css={ProfessoresContent}>
                <h2>Romulo Bolivar</h2>
                <h3>Redação - PROENEM</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor32} alt='' />
              <div css={ProfessoresContent}>
                <h2>Vanessa Silva</h2>
                <h3>HistoÌria - Elite Porto Alegre</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor15} alt='' />
              <div css={ProfessoresContent}>
                <h2>Vitório Gauss</h2>
                <h3>Matemática - Bertoni</h3>
              </div>
            </div>
          </div>
          <div>
            <div css={ProfessoresBox}>
              <img src={Professor26} alt='' />
              <div css={ProfessoresContent}>
                <h2>William Mourão</h2>
                <h3>Matemática - João Pessoa</h3>
              </div>
            </div>
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default SimpleSlider